import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useMatch } from '@reach/router'

import './index.styl'

const Footer = ({ navRoutes, information, sitePrefix, showPolicyContent }) => {
  const {
    address,
    company,
    email,
    fax,
    tel,
    fb,
    yt,
    ig,
  } = information

  const [navs, setNavs] = useState([])
  const { t } =  useTranslation()

  useEffect(() => {
    setNavs([
      {
        ...navRoutes.filter(route => route.slug === 'about')[0],
        subMenu: [],
      },
      ...(showPolicyContent ? [
        {
          label: 'nav_policy',
          slug: 'policy',
          subMenu: [],
        },
      ]: []),
      ...navRoutes.filter(route => route.slug === 'product')
    ])
  }, [])

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__nav-wrap">
          <nav className="footer__nav">
            {navs
              .filter(route => route.slug !== 'index' && route.slug !== 'contact')
              .map(route => (
                  <>
                  {
                    route.slug === 'policy' ? (
                      <div 
                        key={`nav-${route.slug}`} 
                        className="footer__link footer__nav-link"
                        onClick={showPolicyContent}
                      >
                        <div className="footer__link-label">{t(route.label)}</div>
                      </div>
                    ) : (
                      route.url ? (
                        <a
                        key={`nav-${route.slug}`} 
                          href={route.url}
                          target="_blank"
                          className="footer__link footer__nav-link"
                        >
                          <div className="footer__link-label">{t(route.label)}</div>
                        </a>
                      ) : (
                        <a
                          key={`nav-${route.slug}`} 
                          href={`${sitePrefix}/${route.slug}/`}
                          className="footer__link footer__nav-link"
                        >
                          <div className="footer__link-label">{t(route.label)}</div>
                        </a>
                      )
                    )
                  }
                  {
                    route.subMenu.map(subRoute => (
                      subRoute.url ? (
                        <a
                          key={`sub-nav-${subRoute.slug}`}
                          href={subRoute.url}
                          target="_blank"
                          className="footer__link footer__nav-sub-link"
                        >
                          {subRoute.label}
                        </a>
                      ) : (
                        <a
                          key={`sub-nav-${subRoute.slug}`}
                          href={`${sitePrefix}/${route.slug}/${subRoute.slug}/`}
                          className="footer__link footer__nav-sub-link"
                        >
                          {t(subRoute.label)}
                        </a>
                      )                      
                    ))
                  }
                  </>
                )
              )}
          </nav>
        </div>
        <div className="footer__content">
          <div className="footer__location">
            { company && (<div>{company}</div>) }
            { address && (<div>{`ADDRESS：${address}`}</div>) }
            {
              (tel || fax) && (
                <div className="footer__row--tel-fax">
                  { tel && <div>{`TEL： ${tel}　`}</div>}
                  { fax && <div>{`FAX： ${fax}　`}</div>}
                </div>
              )
            }
            { email && (
                <div>
                  E-MAIL：<a className="footer__link" href={`mailto:${email}`}>{email}</a>
                </div>
              )
            }
          </div>
          <div className="footer__social">
            { 
              email && (
                <a className="footer__social-link footer__social-link-mail" href={`mailto:${email}`}>{email}</a>
              )
            }
            {
              fb && (
                <a className="footer__social-link footer__social-link-fb" target="_blank" href={`${fb}`}>
                  Facebook
                </a>
              )
            }
            {
              ig && (
                <a className="footer__social-link footer__social-link-ig" target="_blank" href={`${ig}`}>
                  Instagram
                </a>
              )
            }
            {
              yt && (
                <a className="footer__social-link footer__social-link-yt" target="_blank" href={`${yt}`}>
                  Youtube
                </a>
              )
            }            
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        COPYRIGHT © {new Date().getFullYear()} HWA FONG RUBBER
      </div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
