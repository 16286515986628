export const generateResult = (num, refs, min = 5) => {
  const total = min + Math.floor(Math.random() * num)
  return [...Array(total).keys()].map(
    d => refs[Math.round(Math.random() * (refs.length - 1))]
  )
}

export const randomPickArray = (array, num) => {
  if (num > array.length) return array
  const list = [...array]
  const randomList = []

  while (randomList.length < num) {
    const randomIndex = Math.floor(Math.random() * list.length)
    randomList.push(list.splice(randomIndex, 1)[0])
  }

  return randomList
}
