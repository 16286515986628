import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const IconPrevious = ({ className, color = 'dark', mode = '' }) => (
  <div
    className={classNames('icon-previous', className, {
      'icon-previous--white': color === 'white',
      'icon-previous--triangle': mode === 'triangle',
    })}
  />
)

IconPrevious.propTypes = {}

export default IconPrevious
