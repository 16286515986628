import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const IconBars = ({ className, crossed = false, color }) => (
  <div
    className={classNames('icon-bars', className, {
      'icon-bars--crossed': crossed,
      'icon-bars--dark': color === 'dark',
    })}
  />
)

IconBars.propTypes = {}

export default IconBars
