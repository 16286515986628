import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const LOGO_MODE = {
  index: 'icon-logo--index',
  row: 'icon-logo--row',
  column: 'icon-logo--column',
}

const IconLogo = ({ className, mode = 'row' }) => (
  <div className={classNames('icon-logo', LOGO_MODE[mode], className)} />
)

IconLogo.propTypes = {}

export default IconLogo
