import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Button from '../button'
import { IconBars } from '../icons'

import './index.styl'

const PrivacyPolicy = (props) => {
  const {
    content,
    showContent,
    hideContent,
    closeConfirm,
    isContentShow,
    isConfirmShow,
  } = props

  const { t } =  useTranslation()

  return (
    <div className="privacy-policy">
      {
        isContentShow && (
          <div 
            className="privacy-policy__modal"
            className={classNames(
              'privacy-policy__modal',
              {
                'privacy-policy__modal--full': !isConfirmShow,
              }
            )}
          >
            <div className="privacy-policy__modal-content-wrap">
              <div className="privacy-policy__modal-content">
                <div dangerouslySetInnerHTML={{ __html: props.content}} />
              </div>
              <Button className="privacy-policy__cancel-btn" onClick={hideContent}>
                <IconBars color="dark" crossed={true} />
              </Button>
            </div>
          </div>
        )
      }
      {
        isConfirmShow && (
          <div className="privacy-policy__confirm">
            <div className="privacy-policy__confirm-content">
              <h2 className="privacy-policy__confirm-title">{t('privacy.title')}</h2>
              <p>
               {t('privacy.content')} <span className="privacy-policy__content-open-btn" onClick={showContent}>{t('privacy.more')}</span>
              </p>
            </div>
            <Button 
              className="privacy-policy__confirm-btn" 
              style="primary"
              onClick={ () => closeConfirm(true)}
            >
              {t('privacy.confirm')}
            </Button>
            <Button 
              className="privacy-policy__cancel-btn"
              onClick={ () => closeConfirm()}
            >
              <IconBars color="dark" crossed={true} />
            </Button>
          </div>
        )
      }
    </div>
  )
}

export default PrivacyPolicy
