import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../button'
import { IconNext, IconPrevious } from '../icons'

import './index.styl'

const Pagination = ({
  current = 1,
  perPage = 1,
  total = 0,
  disabled,
  pageClick,
  className,
  showPageBtn = true,
  style = 'normal',
  size = 'normal',
}) => {
  const [totalPage, setTotalPage] = useState(0)
  const [activePages, setActivePages] = useState([])

  useEffect(() => {
    setTotalPage(Math.ceil(total / perPage))
  }, [total, perPage])

  useEffect(() => {
    let newActivePages = []
    if (totalPage > 3) {
      newActivePages =
        current === 1
          ? [1, 2, 3]
          : current === totalPage
          ? [current - 2, current - 1, current]
          : [current - 1, current, current + 1]
    } else {
      for (let i = 1; i <= totalPage; i += 1) newActivePages.push(i)
    }

    setActivePages(newActivePages)

  }, [current, totalPage])

  return (
    <div className={classNames('pagination', className)}>
      <Button
        style={style === 'light' ? 'outline-secondary' : ''}
        disabled={disabled || current === 1}
        onClick={() => pageClick(current - 1)}
        className={classNames({
          'pagination__btn-small': size === 'small',
        })}
      >
        <IconPrevious color={style === 'light' ? 'white' : 'dark'} />
      </Button>
      {showPageBtn &&
        activePages.map(page => (
          <Button
            key={page}
            style={style === 'light' ? 'outline-secondary' : ''}
            disabled={disabled}
            className={classNames('pagination__page', {
              'pagination__page--active': page === current,
              'pagination__btn-small': size === 'small',
            })}
            onClick={() => pageClick(page)}
          >
            {page}
          </Button>
        ))}
      <Button
        style={style === 'light' ? 'outline-secondary' : ''}
        disabled={disabled || current === totalPage}
        onClick={() => pageClick(current + 1)}
        className={classNames({
          'pagination__btn-small': size === 'small',
        })}
      >
        <IconNext color={style === 'light' ? 'white' : 'dark'} />
      </Button>
    </div>
  )
}

Pagination.propTypes = {}

export default Pagination
