import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

import Button from '../../button'
import { IconBars } from '../../icons'

import './index.styl'

const MenuDrawer = ({ navRoutes, sitePrefix }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [hovered, setHovered] = useState(false)

  const { t } =  useTranslation()

  useEffect(() => {
    if (menuOpened) {
      document.body.classList.add('menu-opened')
    } else {
      document.body.classList.remove('menu-opened')
    }
  }, [menuOpened])

  return (
    <div className={classNames('menu', { 'menu--opened': menuOpened })}>
      <Button
        style="primary"
        className={classNames('menu__toogle-btn', {
          'menu__toogle-btn--opened': menuOpened,
        })}
        onMouseOver={() => {
          if (!menuOpened) setMenuOpened(true)
        }}
        onClick={e => {
          e.preventDefault()
          setMenuOpened(!menuOpened)
        }}
      >
        <IconBars crossed={menuOpened} />
        <div className="menu__toogle-senser" />
      </Button>
      <div
        className={classNames('menu__wrap', {
          'menu__wrap--opened': menuOpened,
        })}
        onClick={() => {
          if (!hovered) {
            setMenuOpened(false)
          }
        }}
      >
        <div
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          className={classNames('menu__drawer', {
            'menu__drawer--opened': menuOpened,
          })}
        >
          <ul className="menu__list">
            {navRoutes.map(({ slug, url, label, subMenu = [] }) => (
              <li key={slug} className="menu__list-item">
                <a
                  className="menu__nav-link"
                  href={
                    url ? `${url}` : `${sitePrefix}/${slug === 'index' ? '' : slug + '/'}`
                  }
                >
                  {t(label)}
                </a>
                { slug !=='investor' && subMenu.map(subItem => (
                  <a
                    className="menu__sub-nav-link"
                    key={`${slug}-${subItem.slug}`}
                    href={url ? `${url}` : `${sitePrefix}/${slug}/${subItem.slug}/`}
                  >
                    {t(subItem.label)}
                  </a>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

MenuDrawer.propTypes = {}

export default MenuDrawer
