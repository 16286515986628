import React from 'react'
import classNames from 'classnames'
import { Link } from '@reach/router'

import './style.styl'

const BUTTON_STYLE = {
  primary: 'button--primary',
  secondary: 'button--secondary',
  'outline-secondary': 'button--outline-secondary',
}

const Button = ({
  children,
  className,
  getRef,
  style,
  link,
  href,
  target = '_blank',
  ...props
}) => {
  return link ? (
    <Link
      className={classNames('button', BUTTON_STYLE[style], className)}
      to={link}
      ref={getRef}
      target={target}
      {...props}
    >
      {children}
    </Link>
  ) : href ? (
    <a
      className={classNames('button', BUTTON_STYLE[style], className)}
      href={href}
      ref={getRef}
      target={target}
      {...props}
    >
      {children}
    </a>
  ) : (
    <button
      className={classNames('button', BUTTON_STYLE[style], className)}
      ref={getRef}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
