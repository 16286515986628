import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const IconGoto = ({ className }) => (
  <div className={classNames('icon-goto', className)} />
)

IconGoto.propTypes = {}

export default IconGoto
