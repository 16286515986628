import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IconLoader } from '../icons'

import './index.styl'

let loadedImgs = []

const ImageCard = ({
  className,
  imgUrl,
  limit = 'width',
  mode = 'image',
  caption,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(true)
  const imgUrlRef = useRef()

  const imageOnload = () => {
    loadedImgs.push(imgUrl)
    setLoaded(true)
  }

  useEffect(() => {
    if (imgUrlRef.current && imgUrlRef.current !== imgUrl && loadedImgs.indexOf(imgUrl) === -1) {
      setLoaded(false)
    }
    imgUrlRef.current = imgUrl
  }, [imgUrl])

  return imgUrl ? (
    <figure
      className={classNames('image-card', className, {
        'image-card--background': mode === 'background',
      })}
      style={{
        ...(mode === 'background' && loaded
          ? { backgroundImage: `url(${imgUrl})` }
          : {}),
      }}
      {...rest}
    >
      {!loaded && <IconLoader />}
      <img
        className={classNames('image-card__img', {
          'image-card__img--loading': !loaded,
          'image-card__img--background': mode === 'background',
        })}
        src={imgUrl}
        onLoad={imageOnload}
        onError={imageOnload}
        style={{
          ...(limit === 'width' ? { width: '100%' } : { height: '100%' }),
        }}
      />
      {
        caption && <figcaption className="image-card__caption">{caption}</figcaption>
      }
    </figure>
  ) : null
}

ImageCard.propTypes = {}

export default ImageCard
