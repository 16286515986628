import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const IconSearch = ({ className }) => (
  <div className={classNames('icon-search', className)} />
)

IconSearch.propTypes = {}

export default IconSearch
