import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, navigate, useLocation } from '@reach/router'
import { resolve, parse } from 'url'
import { URLSearchParams } from 'universal-url'

import './index.styl'

const AsideMenu = ({ menu = [], cat, pdfLink, screen, pagePath, sitePrefix }) => {
  const selectEl = useRef(null)
  const location = useLocation()

  const { t } =  useTranslation()

  const [asideMenu, setAsideMenu] = useState([])

  const isCurrent = href => {
    const parseHref = parse(href)

    const urlParams = new URLSearchParams(location.search)
    const parseParams = new URLSearchParams(parseHref.search || '')

    urlParams.delete('p')

    return (
      decodeURIComponent(parseHref.pathname.replace(/\/$/,'')) === decodeURIComponent(location.pathname.replace(/\/$/,'')) &&
        parseParams.toString() === urlParams.toString() 
    )
  }

  const getCurrentPath = () => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.delete('p')
    return urlParams.toString().length 
      ? `${decodeURIComponent(location.pathname)}?${urlParams.toString()}`
      : `${decodeURIComponent(location.pathname)}`
  }


  const handleSelectChange = () => navigate(selectEl.current.value)

  useEffect( () => {
    setAsideMenu(menu)
  }, [menu])

  return (
    <>
      {asideMenu.length > 0 && (
        <aside className="aside">
          {screen === 'xs' ? (
            <select
              className="aside__select"
              ref={selectEl}
              onChange={handleSelectChange}
              defaultValue={getCurrentPath()}
            >
              {menu.map(item => (
                <option key={item.slug} value={`${sitePrefix}/${cat}/${ cat === 'product' ? item.slug : item.slug + '/' }`}>
                  {t(item.label)}
                </option>
              ))}
            </select>
          ) : (
            menu.map(item => (
              <a
                href={`${sitePrefix}/${cat}/${ cat === 'product' ? item.slug : item.slug + '/' }`}
                key={item.slug}
                className={classNames(`aside__link`, {
                  'aside__link--active': isCurrent(
                    `${sitePrefix}/${cat}/${item.slug}`
                  ),
                })}
              >
                {t(item.label)}
              </a>
            ))
          )}
          {pdfLink && (
            <a
              href={pdfLink}
              target="_blank"
              className="aside__link aside__pdf-link"
            >
              { t('types.catalogue') }
            </a>
          )}
        </aside>
      )}
    </>
  )
}

AsideMenu.propTypes = {}

export default AsideMenu
