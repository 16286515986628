import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ImageCard from '../image-card'

import './index.styl'

const ProductCard = ({ type, image, pictures, title, spec, id, sitePrefix, productID, ...rest }) => {
  return (
    <a
      className="product-card"
      href={`${sitePrefix}/product/${type.slug.toLowerCase()}/${id.toLowerCase()}/`}
    >
      <div className="product-card__image-wrap">
        <ImageCard
          className="product-card__image"
          limit="height"
          mode="background"
          imgUrl={pictures[0]}
        />
      </div>
      <div className="product-card__type">{type.label}</div>
      <h5 className="product-card__title">
        {productID}
        { (productID !== title) && (
          <>
            <br/>
            {title}
          </>
        )}
      </h5>
    </a>
  )
}

ProductCard.propTypes = {}

export default ProductCard
