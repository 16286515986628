import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation } from '@reach/router'
import { parse } from 'url'

import './index.styl'

const BreadCrumbs = ({ crumbs, sitePrefix }) => {
  const location = useLocation()
  const { t } =  useTranslation()

  const isCurrent = href => {
    const parseHref = parse(href)
    return parseHref.pathname === location.pathname.replace(/\/$/, '')
  }

  return (
    <>
      {crumbs.length > 1 && (
        <div className="bread-crumbs">
          {crumbs.map(crumb => isCurrent(`${sitePrefix}${crumb.slug}`) ? (
            <div
              key={crumb.slug}
              className={classNames(`bread-crumbs__link`, 'bread-crumbs__link--active')}
            >
              {t(crumb.label)}
            </div>
          ): (
            <a
              href={`${sitePrefix}${crumb.slug}`}
              key={crumb.slug}
              className={classNames(`bread-crumbs__link`)}
            >
              {t(crumb.label)}
            </a>
          ))}
        </div>
      )}
    </>
  )
}

export default BreadCrumbs
