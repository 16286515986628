import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.styl'

const IconNext = ({ className, color = 'dark', mode = '' }) => (
  <div
    className={classNames('icon-next', className, {
      'icon-next--white': color === 'white',
      'icon-next--triangle': mode === 'triangle',
    })}
  />
)

IconNext.propTypes = {}

export default IconNext
