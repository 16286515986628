import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation } from '@reach/router'
import { parse } from 'url'

import duroLogo from './duro_logo.svg'
import { IconLogo } from '../icons'
import SiteSwitch from './site-switch'
import MenuDrawer from './menu-drawer'

import './index.styl'

const Header = ({ lang, navRoutes, pagePath, sitePrefix, siteSwitchData }) => {
  const location = useLocation()
  const { t } =  useTranslation()

  const isActive = href => {
    const parseHref = parse(href)
    return location.pathname.includes(parseHref.pathname)
  }

  const isPartiallyActive = ({ isPartiallyCurrent }) => ({
    className: `nav-link ${isPartiallyCurrent ? 'nav-link--active' : ''}`,
  })

  return (
    <header className="header">
      <a className="index-link" href={`${sitePrefix}`}>
        <IconLogo mode="index" />
      </a>
      <div
        className={classNames('nav-bar', {
          'nav-bar--transparent': pagePath === '/',
        })}
      >
        <nav>
          {navRoutes
            .filter(route => route.slug !== 'index')
            .map(route =>
              route.url ? (
                <a
                  key={route.slug}
                  href={`${route.url}`}
                  target="_blank"
                  className="nav-link"
                >
                  <div className="nav-link__label">{t(route.label)}</div>
                </a>
              ) : (
                <a
                  key={route.slug}
                  className={classNames(`nav-link`, {
                    'nav-link--active': isActive(`${sitePrefix}/${route.slug}/`),
                  })}
                  href={`${sitePrefix}/${route.slug}/`}
                >
                  <div className="nav-link__label">{t(route.label)}</div>
                </a>
              )
            )}
        </nav>
        <SiteSwitch site={lang} siteSwitchData={siteSwitchData} />
      </div>
      <MenuDrawer navRoutes={navRoutes} sitePrefix={sitePrefix} />
    </header>
  )
}

export default Header
