import React, { useEffect, useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, useLocation } from '@reach/router'
import Cookies from 'js-cookie'

import Header from '../header'
import Footer from '../footer'
import BreadCrumbs from '../bread-crumbs'
import AsideMenu from '../aside-menu'
import PrivacyPolicy from '../privacy-policy'

import { IconLoader } from '../icons'

import { siteData } from '../../utils/site-data'

import useScreen from '../../hooks/use-screen'

import i18n from '../../i18n'

import './reset.css'
import './index.styl'

const ASIDE_DEFAULT = {
  site: '',
  cat: '',
  menu: [],
}

let setLanguage = false

const Layout = ({
    lang = 'en',
    children,
    aside = ASIDE_DEFAULT,
    pagePath,
    crumbs,
    siteSwitchData,
    sitePrefix,
    navRoutes,
    information,
    privacyPolicy,
  }) => {

  const screen = useScreen()
  const location = useLocation()

  const [ isPolicyShow, setIsPolicyShow ] = useState(false)
  const [ isPolicyConfirmShow, setIsPolicyConfirmShow ] = useState(false)

  const showPolicyContent = () => {
    setIsPolicyShow(true)
  }

  const hidePolicyContent = () => {
    setIsPolicyShow(false)
  }

  const closePolictConfirm = (isOk) => {
    if (isOk) {
      const privacyCookieName = `duro-privacy-${new Date(privacyPolicy.edit_time).getTime()}`
      Cookies.set(privacyCookieName, 1, { expires: 3650 })
    }
    setIsPolicyConfirmShow(false)
  }

  useEffect(() => {
    //i18n.changeLanguage(lang)
    if (privacyPolicy.content) {
      const privacyCookieName = `duro-privacy-${new Date(privacyPolicy.edit_time).getTime()}`
      if (!Cookies.get(privacyCookieName)) {
        setIsPolicyConfirmShow(true)
      }
    }
  }, [])

  if (!setLanguage) {
    i18n.changeLanguage(lang)
    setLanguage = true
  }

  return (
    <>
      <Header lang={lang} navRoutes={navRoutes} pagePath={pagePath} sitePrefix={sitePrefix} siteSwitchData={siteSwitchData} />
      <BreadCrumbs crumbs={crumbs} sitePrefix={sitePrefix} />
      <main>
        {aside.menu.length > 0 ? (
          <div className="section">
            <AsideMenu
              {...aside}
              screen={screen}
              pagePath={pagePath}
            />
            <div className="content">
              {children}
            </div>
          </div>
        ) : (
          <div className="content">
            {children}
          </div>
        )}
      </main>
      <Footer 
        lang={lang} 
        navRoutes={navRoutes} 
        information={information} 
        sitePrefix={sitePrefix}
        showPolicyContent={ privacyPolicy.content ? showPolicyContent : null}
      />
      {
        privacyPolicy.content && (
          <PrivacyPolicy 
            {...privacyPolicy}
            isContentShow={isPolicyShow}
            isConfirmShow={isPolicyConfirmShow}
            showContent={showPolicyContent}
            hideContent={hidePolicyContent}
            closeConfirm={closePolictConfirm}
          />
        )
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
