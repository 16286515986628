import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import './index.styl'

const siteSwitchData = [
  { slug: 'en', label: 'EN', link: 'http://www.duro.com.tw/en'},
  { slug: 'us', label: 'US', link: 'http://www.duro.com.tw/us'},
  { slug: 'zh', label: 'TW', link: 'http://www.duro.com.tw/tw'},
  { slug: 'th', label: 'TH', link: 'http://www.duro.com.tw/th'},
]

const SiteSwitch = ({ site = 'en' }) => {
  const { t } =  useTranslation()
  const selectedSite = siteSwitchData.filter(d => d.slug === site)[0].label

  return (
    <div className="site-switch">
      <div
        className={classNames('site-switch__toggle-btn', {
          'site-switch__toggle-btn--disabled': siteSwitchData.length <= 1,
        })}
      >
        { t(`site.${selectedSite}`) }
      </div>
      <ul className="site-switch__menu">
        {siteSwitchData
          .filter(d => d.slug !== site)
          .map(d => (
            <li key={d.slug} className="site-switch__menu-item">
              <a className="site-switch__link" href={d.link}> {t(`site.${d.label}`)}</a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default SiteSwitch
